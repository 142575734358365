<template>
  <n-space vertical>
    <n-layout>
      <n-layout-header style="padding: 1px; height: 70px" :inverted="inverted" bordered>
        <div>
          <n-row>
            <n-col :span="12">
              <h2 style="padding-left: 30px; font-weight: 200">CDN管理后台</h2>
            </n-col>
            <n-col style="text-align: right" :span="12">
              <div style="padding: 17px 30px 10px 30px">
                <n-button
                  quaternary
                  round
                  type="tertiary"
                  v-if="isMobile"
                  @click="changeTheme"
                  >你好，{{ userInfo.username }}</n-button
                >
                &nbsp;
                <n-button v-if="isMobile" round type="info" @click="changeTheme">
                  切换主题 </n-button
                >&nbsp;
                <n-button round type="primary" @click="logout"> 退出登录 </n-button>
              </div>
            </n-col>
          </n-row>
        </div>
      </n-layout-header>
      <n-layout has-sider style="height: calc(100vh - 70px)">
        <n-layout-sider
          bordered
          show-trigger
          collapse-mode="width"
          :collapsed-width="64"
          :width="200"
          :native-scrollbar="false"
          :collapsed="onlyShowIcon"
          @collapse="onlyShowIcon = true"
          @expand="onlyShowIcon = false"
          :inverted="inverted"
        >
          <n-menu
            :inverted="inverted"
            v-model:value="defaultActive"
            :collapsed-width="64"
            :collapsed-icon-size="22"
            :options="menuData"
          />
        </n-layout-sider>
        <n-layout-content content-style="padding: 24px;" style="width: 100%">
          <router-view />
        </n-layout-content>
        <n-layout />
      </n-layout>
    </n-layout>
  </n-space>
</template>

<script lang="ts">
import { h, reactive, defineComponent, ref, Component } from "vue";
import { NIcon } from "naive-ui";

import { RouteRecordNormalized, useRouter, RouterLink } from "vue-router";
import { requestApi } from "../plugins/common";

function renderIcon(icon: Component) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

export default defineComponent({
  setup() {
    const notification = window.$notification;
    const router = useRouter();
    let documentElementR = reactive(document.documentElement);
    let isMobile = ref(documentElementR.clientWidth > 800);
    let onlyShowIcon = ref(documentElementR.clientWidth < 800);
    let inverted = ref(localStorage.getItem("theme") != "white");
    let menuOptions = ref([]);
    let userType = ref("");
    let userInfo = ref({
      username: "LOADING",
    } as any);
    const routerList = router.getRoutes();
    let allRouterList: any[] | RouteRecordNormalized = routerList;
    let defaultActive = ref("");
    let menuData = ref([] as any);

    // 轮询接口实时更新用户信息
    let getUserInfoTimer = setInterval(() => {
      getUserInfo();
    }, 10000);
    localStorage.setItem("get_user_info_timer", getUserInfoTimer.toString());

    // 获取用户信息
    getUserInfo();
    function getUserInfo() {
      requestApi(
        "/user/info",
        function (res: any) {
          if (res.code == 1) {
            userInfo.value = res.data;
            localStorage.setItem("user_info", res.data);
            userType.value = res.data.u_type;
            if (res.data.u_type != "admin" && res.data.u_type != "agent") {
              notification["warning"]({
                content: "没有权限",
                meta: `抱歉，您没有访问本平台的权限！`,
                duration: 2500,
                keepAliveOnHover: true,
              });
            }
            menuData.value = [];
            menuData = menuEach(menuData, allRouterList as any);
            //userName.value = res.data.username;
          } else {
            localStorage.removeItem("jwt_token");
            router.push({ path: "/login" });
            notification["warning"]({
              content: "登录过期",
              meta: `抱歉，您的登录信息似乎已经过期，请尝试重新登录！`,
              duration: 2500,
              keepAliveOnHover: true,
            });
          }
        },
        false,
        {},
        {},
        "get",
        false,
        false
      );
    }

    function logout() {
      notification["success"]({
        content: "注销",
        meta: `成功退出登录，您可以重新登录其他账户！`,
        duration: 2500,
        keepAliveOnHover: true,
      });
      localStorage.removeItem("jwt_token");
      router.push({ path: "/login" });
    }

    function menuEach(menuData: any, menuList: any[]) {
      let index = 0;
      menuList.forEach((menuInfo) => {
        if (menuInfo.meta.name != undefined) {
          if (menuInfo.name == router.currentRoute.value.name) {
            defaultActive.value = index.toString();
          }
          if (userType.value != menuInfo.meta.allow && menuInfo.meta.allow != "all") {
            return;
          }
          menuData.value.push({
            key: index.toString(),
            label: () =>
              h(
                RouterLink,
                {
                  to: {
                    name: menuInfo.name,
                  },
                },
                { default: () => menuInfo.meta.name }
              ),
            icon: renderIcon(menuInfo.meta.icon),
          });
          index++;
        }
      });
      return menuData;
    }

    function changeTheme() {
      localStorage.setItem("theme", inverted.value ? "white" : "black");
      inverted.value = !inverted.value;
    }

    return {
      documentElementR,
      menuData,
      onlyShowIcon,
      defaultActive,
      userInfo,
      logout,
      isMobile,
      changeTheme,
      inverted,
    };
  },
});
</script>
