declare global {
  interface Window {
    $notification: any;
    $dialog: any;
    $loadingBar: any;
  }
}

export const requestApi = (path: string, callback: any = undefined, showNotification = true, query: any = [], args: object = {}, method: any = 'get', callbackFail: any = undefined, showLoading = true) => {
  const loadingBar = window.$loadingBar
  const notification = window.$notification
  if (showLoading) {
    loadingBar.start()
  }
  const queryArr: any = []
  Object.keys(query).forEach(function (key) {
    queryArr.push(key + "=" + query[key])
  })
  const queryString = queryArr.join('&')
  let reqPath = process.env.VUE_APP_BASE_API + path
  if (queryString != '') {
    reqPath += '?' + queryString
  }
  const authHeader = localStorage.getItem('jwt_token') == null ? '' : 'Bear ' + localStorage.getItem('jwt_token')
  const requestInit: RequestInit = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authHeader
    }
  }
  if (method != 'get' && method != 'head') {
    requestInit.body = JSON.stringify(args)
  }
  fetch(reqPath, requestInit)
    .then(res => res.json())
    .then(res => {
      if (showLoading) {
        loadingBar.finish()
      }
      callback(res)
      if (showNotification) {
        let rMsg = res.msg
        if (res.data.err != undefined) {
          rMsg += ` [${res.data.err}]`
        }
        notification[res.code == 1 ? 'success' : 'warning']({
          content: res.code == 1 ? '操作成功' : '操作失败',
          meta: rMsg,
          duration: 2500,
          keepAliveOnHover: true
        })
      }
    }).catch(error => {
      if (showLoading) {
        loadingBar.error()
      }
      notification['error']({
        content: '请求失败',
        meta: `请求接口时遇到了一个致命的错误，请联系管理员！[${error.message}]`,
        duration: 2500,
        keepAliveOnHover: true
      })
      if (callbackFail) {
        callback(error)
      }
    })

}


export const startCdn = (domain: string) => {
  const notification = window.$notification;
  return new Promise((resolve, reject) => {
    requestApi(
      "/cdn/start",
      (res: any) => {
        setTimeout(resolve, 1000);
      },
      true,
      {},
      { domain: domain },
      "post",
      () => {
        setTimeout(reject, 1000);
        notification["error"] = {
          content: "启动失败",
          meta: `域名[${domain}]启动失败`,
          duration: 2500,
          keepAliveOnHover: true,
        };
      }
    );
  });
};

export const formatDate = (value: number) => {
  const date = new Date(value);
  const y: any = date.getFullYear();
  let MM: any = date.getMonth() + 1;
  MM = MM < 10 ? ('0' + MM) : MM;
  let d: any = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h: any = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  let m: any = date.getMinutes();
  m = m < 10 ? ('0' + m) : m;
  let s: any = date.getSeconds();
  s = s < 10 ? ('0' + s) : s;
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
}

export const toTwoNumber = (val: any, func = false) => {
  if (val.toFixed(2) == "0.00" && func) {
    return "0.01";
  }
  return val.toFixed(2);
}