import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import naive from 'naive-ui'

const apiPath = process.env.VUE_APP_BASE_API

createApp(App).use(router).use(naive).mount('#app')
fetch(apiPath + '/common/config')
  .then(res => res.json())
  .then(res => {
    if (res.code == 1) {
      localStorage.setItem('site_name', res.data.site_name);
      localStorage.setItem('site_icp', res.data.icp);
      localStorage.setItem('site_logo', res.data.logo);
    }
  })