import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import {
  BrowsersOutline,
  FunnelOutline,
  RefreshOutline,
  ChatbubbleOutline,
  PeopleOutline,
  GlobeOutline,
  DiamondOutline,
  LockClosedOutline,
  ReceiptOutline,
  ReaderOutline,
  AlbumsOutline,
  SettingsOutline
} from '@vicons/ionicons5'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    meta: {
      title: '首页',
      allow: 'all'
    },
    component: () => import('../views/HomeView.vue'),
  }, {
    path: '/dashboard',
    name: 'MainPage',
    component: MainLayout,
    children: [
      {
        path: 'admin',
        name: 'AdminDashboard',
        children: [
          {
            path: 'overview',
            name: 'AdminOverView',
            component: () => import('../views/admin/OverView.vue'),
            meta: {
              title: '概览',
              name: '概览',
              allow: 'admin',
              icon: BrowsersOutline
            },
          },
          {
            path: 'statistics',
            name: 'AdminCdnStatistics',
            component: () => import('../views/CdnStatisticsView.vue'),
            meta: {
              title: '流量查询',
              name: '流量查询',
              allow: 'admin',
              icon: FunnelOutline
            },
          },
          {
            path: 'user',
            name: 'AdminUserManage',
            component: () => import('../views/admin/UserManage.vue'),
            meta: {
              title: '用户管理',
              name: '用户管理',
              allow: 'admin',
              icon: PeopleOutline
            }
          },
          {
            path: 'cdn',
            name: 'AdminCdnManage',
            component: () => import('../views/admin/CdnManage.vue'),
            meta: {
              title: '网站管理',
              name: '网站管理',
              allow: 'admin',
              icon: GlobeOutline
            }
          },
          {
            path: 'ticket',
            name: 'TicketListManage',
            component: () => import('../views/TicketListView.vue'),
            meta: {
              title: '工单列表',
              name: '工单列表',
              allow: 'admin',
              icon: ChatbubbleOutline
            }
          },
          {
            path: 'ticket/detail',
            name: 'TicketDetailManage',
            component: () => import('../views/TicketDetailView.vue'),
            meta: {
              title: '工单列表',
              allow: 'admin',
              icon: ChatbubbleOutline
            }
          },
          {
            path: 'package',
            name: 'AdminPackageManage',
            component: () => import('../views/admin/PackageManage.vue'),
            meta: {
              title: '流量包管理',
              name: '流量包管理',
              allow: 'admin',
              icon: DiamondOutline
            }
          },
          {
            path: 'agent',
            name: 'AdminAgentManage',
            component: () => import('../views/admin/AgentManage.vue'),
            meta: {
              title: '代理商管理',
              name: '代理商管理',
              allow: 'admin',
              icon: ReaderOutline
            }
          },
          {
            path: 'cert',
            name: 'AdminCertManage',
            component: () => import('../views/admin/CertManage.vue'),
            meta: {
              title: '证书列表',
              name: '证书列表',
              allow: 'admin',
              icon: LockClosedOutline
            }
          },
          {
            path: 'bill',
            name: 'AdminBillManage',
            component: () => import('../views/BillManage.vue'),
            meta: {
              title: '账单列表',
              name: '账单列表',
              allow: 'admin',
              icon: ReceiptOutline
            }
          },
          {
            path: 'log',
            name: 'AdminLogView',
            component: () => import('../views/admin/LogView.vue'),
            meta: {
              title: '日志查看',
              name: '日志查看',
              allow: 'admin',
              icon: AlbumsOutline
            }
          },
          {
            path: 'setting',
            name: 'AdminSiteSettingView',
            component: () => import('../views/admin/SiteSettingView.vue'),
            meta: {
              title: '系统设置',
              name: '系统设置',
              allow: 'admin',
              icon: SettingsOutline
            }
          }
        ]
      }, {
        path: 'agent',
        name: 'Dashboard',
        children: [
          {
            path: 'overview',
            name: 'AgentOverView',
            component: () => import('../views/agent/OverView.vue'),
            meta: {
              title: '概览',
              name: '概览',
              allow: 'agent',
              icon: BrowsersOutline
            },
          },
          {
            path: 'statistics',
            name: 'AgentCdnStatistics',
            component: () => import('../views/CdnStatisticsView.vue'),
            meta: {
              title: '流量查询',
              name: '流量查询',
              allow: 'agent',
              icon: FunnelOutline
            },
          },
          {
            path: 'user',
            name: 'AgentUserManage',
            component: () => import('../views/agent/UserManage.vue'),
            meta: {
              title: '用户管理',
              name: '用户管理',
              allow: 'agent',
              icon: PeopleOutline
            }
          },
          {
            path: 'user',
            name: 'AgentCdnManage',
            component: () => import('../views/agent/CdnManage.vue'),
            meta: {
              title: '网站管理',
              name: '网站管理',
              allow: 'agent',
              icon: GlobeOutline
            }
          },
          {
            path: 'bill',
            name: 'AgentBillManage',
            component: () => import('../views/BillManage.vue'),
            meta: {
              title: '账单列表',
              name: '账单列表',
              allow: 'agent',
              icon: ReceiptOutline
            }
          },
          {
            path: 'withdraw',
            name: 'WithdrawView',
            component: () => import('../views/agent/WithdrawView.vue'),
            meta: {
              title: '提现列表',
              name: '提现列表',
              allow: 'agent',
              icon: DiamondOutline
            }
          },
          {
            path: 'setting',
            name: 'AgentAccountSetting',
            component: () => import('../views/agent/AccountSetting.vue'),
            meta: {
              title: '代理设置',
              name: '代理设置',
              allow: 'agent',
              icon: SettingsOutline
            }
          }
        ]
      }
    ],
  }, {
    path: '/login',
    name: 'LoginPage',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '登录页面',
      allow: 'all',
      icon: 'XX'
    },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFoundView',
    component: () => import('../views/NotFoundView.vue'),
    meta: {
      title: '找不到指定页面',
      allow: 'all'
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const allowURL = [
    '/login',
  ]
  const notification = window.$notification;
  if (allowURL.indexOf(to.path) == -1 && to.path != "/") {
    const jwtToken = localStorage.getItem('jwt_token')
    if (typeof (jwtToken) == 'object') {
      notification["warning"]({
        content: "登录过期",
        meta: `抱歉，您的登录信息已经过期，请重新登录！`,
        duration: 2500,
        keepAliveOnHover: true,
      });
      return next('/login')
    }
    const userType = localStorage.getItem('user_type') as string;
    if (to.path.indexOf(userType) == -1 && to.path.indexOf('common') == -1) {
      notification["warning"]({
        content: "无权限",
        meta: `您没有权限访问本页`,
        duration: 2500,
        keepAliveOnHover: true,
      });
      return next('/')
    }
  }

  let siteName = localStorage.getItem('site_name') as string
  siteName = siteName == null ? window.location.host as string : siteName
  if (to.meta.title && typeof (to.meta?.title) === 'string') {
    document.title = to.meta.title + ' - ' + siteName
  } else {
    document.title = siteName
  }
  next()
})
export default router
